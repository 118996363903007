import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'profile',
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/auth/Profile.vue'),
      meta: {
        authOnly: true,
        title: 'Profile',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/pages/Dashboard/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Dashboard',
      },
    },
    {
      path: '/company',
      name: 'company',
      component: () => import('@/views/pages/Company/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Company',
      },
    },
    {
      path: '/company/create',
      name: 'add-company',
      component: () => import('@/views/pages/Company/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Company',
      },
    },
    {
      path: '/company/edit/:id',
      name: 'edit-company',
      component: () => import('@/views/pages/Company/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Company',
      },
    },
    {
      path: '/trip',
      name: 'trip-list',
      component: () => import('@/views/pages/Trip/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Trips',
      },
    },
    {
      path: '/load-board',
      name: 'load-board',
      component: () => import('@/views/pages/Loadboard/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Load Board',
      },
    },
    {
      path: '/load-board/create',
      name: 'add-load',
      component: () => import('@/views/pages/Loadboard/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Load Board',
      },
    },
    {
      path: '/load-board/edit/:id',
      name: 'edit-load',
      component: () => import('@/views/pages/Loadboard/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Load Board',
      },
    },
    {
      path: '/warehouse',
      name: 'warehouse',
      component: () => import('@/views/pages/Warehouse/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Warehouse',
      },
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/views/pages/Payment/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Payments',
      },
    },
    {
      path: '/payment/detail/:id',
      name: 'payment-detail',
      component: () => import('@/views/pages/Payment/InvoiceDetail.vue'),
      meta: {
        authOnly: true,
        title: 'Payments',
      },
    },
    {
      path: '/invoice',
      name: 'invoice',
      component: () => import('@/views/pages/Invoice/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Invoices',
      },
    },
    {
      path: '/facility/sequence',
      name: 'facility-sequence',
      component: () => import('@/views/pages/Facility/Sequence.vue'),
      meta: {
        authOnly: true,
        title: 'Facility',
      },
    },
    {
      path: '/facility/code',
      name: 'facility-code',
      component: () => import('@/views/pages/Facility/Code.vue'),
      meta: {
        authOnly: true,
        title: 'Facility',
      },
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('@/views/pages/User/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Users',
      },
    },
    {
      path: '/user/create',
      name: 'add-user',
      component: () => import('@/views/pages/User/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Users',
      },
    },
    {
      path: '/user/edit/:id',
      name: 'edit-user',
      component: () => import('@/views/pages/User/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Users',
      },
    },
    {
      path: '/vendor',
      name: 'vendor',
      component: () => import('@/views/pages/Vendor/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Vendors',
      },
    },
    {
      path: '/vendor/create',
      name: 'add-vendor',
      component: () => import('@/views/pages/Vendor/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Vendors',
      },
    },
    {
      path: '/vendor/edit/:id',
      name: 'edit-vendor',
      component: () => import('@/views/pages/Vendor/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Vendors',
      },
    },
    {
      path: '/asset',
      name: 'asset',
      component: () => import('@/views/pages/Asset/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Assets',
      },
    },
    {
      path: '/asset/create',
      name: 'add-asset',
      component: () => import('@/views/pages/Asset/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Assets',
      },
    },
    {
      path: '/asset/edit/:id',
      name: 'edit-asset',
      component: () => import('@/views/pages/Asset/Form.vue'),
      meta: {
        authOnly: true,
        title: 'Assets',
      },
    },
    {
      path: '/callbackQBO',
      name: 'callbackQBO',
      component: () => import('@/views/auth/Callback.vue'),
      meta: {
        layout: 'full',
        authOnly: true,
        title: 'callbackQBO',
      },
    },
    {
      path: '/report',
      name: 'report',
      component: () => import('@/views/pages/Report/Index.vue'),
      meta: {
        authOnly: true,
        title: 'Report',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        guestOnly: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
        guestOnly: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        guestOnly: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

function isLoggedIn() {
  return localStorage.getItem('token')
}

router.beforeEach((to, from, next) => {
  let customRoles = []
  if (localStorage.getItem('custom_roles')) {
    customRoles = localStorage.getItem('custom_roles').split(',')
  }
  if (to.matched.some(record => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: '/login',
      })
    } else if (customRoles && customRoles.includes(to.meta.title)) {
      next()
    } else {
      next({
        path: '/profile',
      })
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn()) {
      next({
        path: '/profile',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
