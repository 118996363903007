module.exports = {
  auth: {
    register: 'auth/register',
    login: 'auth/login',
    getUserByToken: 'auth/user',
  },
  payment: {
    importCSV: 'payment/importCSV',
    get: 'payment/get',
    detail: 'payment/detail',
    getPaymentOfTrip: 'payment/getPaymentOfTrip',
  },
  trip: {
    importCSV: 'trip/importCSV',
    get: 'trip/get',
    format: 'trip/format',
  },
  facility: {
    get: 'facility/get',
    create: 'facility/create',
    edit: 'facility/edit',
    update: 'facility/update',
    sequence: 'facility/sequence',
    importCodeInfo: 'facility/importCodeInfo',
    exportCodeCsv: 'facility/exportCodeCsv',
  },
  invoice: {
    get: 'invoice/get',
    sendInvoice: 'invoice/sendInvoice',
    connectQBO: 'invoice/connectQBO',
    callbackQBO: 'invoice/callbackQBO',
  },
  user: {
    get: 'user/get',
    create: 'user/create',
    edit: 'user/edit',
    update: 'user/update',
    delete: 'user/delete',
    getRoles: 'user/getRoles',
    getUsersByRole: 'user/getUsersByRole',
  },
  company: {
    get: 'company/get',
    create: 'company/create',
    edit: 'company/edit',
    update: 'company/update',
    delete: 'company/delete',
    getCompanyName: 'company/getCompanyName',
    getCompanyId: 'company/getCompanyId',
    getCompanyByCompany: 'company/getCompanyByCompany',
  },
  vendor: {
    get: 'vendor/get',
    create: 'vendor/create',
    edit: 'vendor/edit',
    update: 'vendor/update',
    delete: 'vendor/delete',
    getVendorShortDetails: 'vendor/getVendorShortDetails',
  },
  asset: {
    get: 'asset/get',
    create: 'asset/create',
    edit: 'asset/edit',
    update: 'asset/update',
    delete: 'asset/delete',
  },
  load: {
    get: 'load/get',
    create: 'load/create',
    edit: 'load/edit',
    update: 'load/update',
    delete: 'load/delete',
    handles: 'load/handles',
    assign: 'load/assign',
    readyToBill: 'load/readyToBill',
    createInvoice: 'load/createInvoice',
    expectMoney: 'load/expectMoney',
    finalized: 'load/finalized',
  },
  report: {
    get: 'report/get',
    getStatics: 'report/getStatics',
  },
  warehouse: {
    get: 'warehouse/get',
  },
}
