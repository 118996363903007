import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueCompositionAPI from '@vue/composition-api'
import VuePapaParse from 'vue-papa-parse'
import lodash from 'lodash'
import dotenv from 'dotenv'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VuePapaParse)
Vue.use(VueSweetalert2)
// Vue.prototype._ = lodash
dotenv.config()

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAEOc6B22kEbMvPCPVlfFZW35zllcfOSOE',
    libraries: 'places',
  },
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Object.defineProperty(Vue.prototype, '$lodash', { value: lodash })

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
